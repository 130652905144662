<template>
  <span>
    <span class="w-3 h-3 circle rounded-full absolute bg-red-600"></span>
    <span class="w-4 h-4 ring rounded-full absolute border-3 border-red-600"></span>
  </span>
</template>

<script>
export default {
  name: 'RedDot',
};
</script>

<style scoped>
  .circle {
    top: -9px;
    right: -13px;
  }
  .ring {
    top: -11px;
    right: -15px;
    -webkit-animation: pulsate 1s ease-out;
    animation: pulsate 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    opacity: 0;
  }
  @-webkit-keyframes pulsate {
    0% {
      -webkit-transform: scale(0.1, 0.1); opacity: 0.0;
    }
    50% {
      opacity: 1.0;
    }
    100% {
      -webkit-transform: scale(1.2, 1.2); opacity: 0.0;
    }
  }
  @keyframes pulsate {
    0% {
      -webkit-transform: scale(0.1, 0.1); opacity: 0.0;
    }
    50% {
      opacity: 1.0;
    }
    100% {
      -webkit-transform: scale(1.2, 1.2); opacity: 0.0;
    }
  }
</style>
