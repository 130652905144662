import api from '../store/api';
import router from '../router';

export default function setup(_this) {
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        error.response.status !== 401 &&
        error.response.data &&
        !error.response.data.message &&
        !error.response.data.token
      ) {
        const errors = [];
        for (const fieldName in error.response.data) {
          if (Object.keys(error.response.data).length > 0) {
            let errorString = '';
            if (Array.isArray(error.response.data[fieldName])) {
              errorString = `${error.response.data[fieldName][0]}`;
              errors.push({
                fieldName,
                message: errorString,
              });
            } else if (typeof error.response.data[fieldName] === 'string') {
              errorString = error.response.data[fieldName];
              errors.push({
                message: errorString,
              });
            } else {
              const keyValue = error.response.data[fieldName];
              const objError = keyValue[Object.keys(keyValue)[0]];
              const errorString = Array.isArray(objError) ? `${objError[0]}` : objError;
              errors.push({
                message: errorString,
              });
            }
          }
        }
        // short-term workaround until back-end error message is updated; only affects login errors
        // TODO: remove when back-end error is updated
        const filterForLoginErrors = errors
          .filter((error) => error.message !== 'invalid_grant')
          .map((error) => {
            if (error.message === 'Invalid credentials given.') {
              error.message = 'Invalid email or password. Try again!';
            }
            return error;
          });
        _this.$store.commit('UPDATE_GLOBAL_ERRORS', filterForLoginErrors);
        return Promise.reject(error);
      }

      if (error.response.status === 401) {
        _this.$store.commit('CLEAR_STATE');
        localStorage.clear();
        router.push({ name: 'login' });
        return Promise.reject(error);
      }

      _this.$store.commit('UPDATE_GLOBAL_ERRORS', ['Your request failed. Try again later.']);
      return Promise.reject(error);
    },
  );
}
