<template>
    <span class="px-2 flex items-center" v-if="complete">
      <SuccessIcon />
    </span>
    <span class="px-2 flex items-center" v-else>
      <EmptyIcon/>
    </span>
</template>

<script>
import EmptyIcon from '@/assets/empty_icon.svg';
import SuccessIcon from '@/assets/success_icon.svg';

export default {
  name: 'BannerIcon',
  components: {
    EmptyIcon,
    SuccessIcon,
  },
  props: {
    /**
     * includes what should be displayed in banner
     */
    complete: {
      default: () => false,
      type: Boolean,
    },
  },
};

</script>

<style scoped>

</style>
