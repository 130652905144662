export default function (dateString) {
  const days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
    12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31];

  const months = ['January', 'February', 'March', 'April', 'May',
    'June', 'July', 'August', 'September', 'October',
    'November', 'December',
  ];

  if (dateString) {
    const segments = dateString.split('-');
    const year = segments[0];
    const monthNumber = Number(segments[1]);
    const dayNumber = Number(segments[2]);
    const month = months[monthNumber - 1];
    const day = days[dayNumber - 1];
    return `${month} ${day}, ${year}`;
  }

  return dateString;
}
