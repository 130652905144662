export default {
  bind(el, binding, vNode) {
    el.vueClickOutside = (event) => {
      if (!el.contains(event.target)) {
        // Call method provided in v-click-outside value
        vNode.context[binding.expression](event);
        event.stopPropagation();
      }
    };
    document.body.addEventListener('click', el.vueClickOutside);
  },
  unbind(el) {
    // Remove event listeners
    document.removeEventListener('click', el.vueClickOutside);
    el.vueClickOutside = null;
  },
};
