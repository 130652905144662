import api from '../api';
import { createFormData } from '../../helpers';

const state = {
  user: {},
  videoModalOpen: false,
  notifications: [],
};

const getters = {
  user: ({ user }) => user,
  videoModalOpen: ({ videoModalOpen }) => videoModalOpen,
  notifications: ({ notifications }) => notifications,
};

const mutations = {
  SET_USER: (state, payload) => {
    state.user = payload;
  },
  UPDATE_VIDEO_MODAL: (state, payload) => {
    state.videoModalOpen = payload;
  },
  SET_NOTIFICATIONS: (state, payload) => {
    state.notifications = payload;
  },
};

const actions = {
  updateVideoRecorderModal: ({ commit }, videoModalOpen) => {
    commit('UPDATE_VIDEO_MODAL', videoModalOpen);
  },
  updateUser: ({ commit }, { groupSlug, profileSlug, user, isAdmin }) => {
    const token = localStorage.getItem('access-token');

    commit('UPDATE_IS_LOADING', true, { root: true });
    commit('CLEAR_ERRORS', true, { root: true });

    const {
      firstName,
      lastName,
      city,
      state,
      country,
      showEmail,
      link,
      bio,
      isAttendingNextEvent,
      isDeceased,
      profilePicture,
      previousFirstName,
      previousLastName,
      school,
      major,
      employer,
      status,
      receiveAdminEmails,
      socialMedia,
      email,
      streetAddress,
      phoneNumber,
    } = user;
    const finalData = {};
    finalData.firstName = firstName;
    finalData.lastName = lastName;
    finalData.showEmail = showEmail;
    finalData.email = email;
    finalData.isAttendingNextEvent = isAttendingNextEvent;
    finalData.isDeceased = isDeceased;
    finalData.receiveAdminEmails = receiveAdminEmails;
    finalData.streetAddress = streetAddress;
    finalData.phoneNumber = phoneNumber;
    if (status && isAdmin) {
      finalData.status = status;
    }

    finalData.bio = bio || '';
    finalData.previousFirstName = previousFirstName || '';
    finalData.previousLastName = previousLastName || '';
    finalData.school = school || '';
    finalData.major = major || '';
    finalData.employer = employer || '';
    finalData.city = city || '';
    finalData.state = state || '';
    finalData.country = country || '';
    finalData.link = link || '';

    // these don't get done automagically
    let finalSocial = null;
    if (socialMedia) {
      finalSocial = [];
      socialMedia.forEach((element) => {
        finalSocial.push({ media_type: element.mediaType, url: element.url });
      });
    }
    const form_data = new FormData();
    createFormData(finalData, form_data);
    if (finalSocial) {
      form_data.append('user', `{"social_media":${JSON.stringify(finalSocial)}}`);
    }

    if (profilePicture && typeof profilePicture !== 'string') {
      form_data.append('profilePicture', profilePicture, 'profilephoto.jpg');
    }

    return api
      .patch(`api/v1/groups/${groupSlug}/profile/${profileSlug}/`, form_data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return {
          success: true,
          profile: data,
        };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },

  fetchCurrentUser: ({ commit }) => {
    const token = localStorage.getItem('access-token');

    commit('UPDATE_IS_LOADING', true, { root: true });
    commit('CLEAR_ERRORS', true, { root: true });

    return api
      .get('api/v1/profiles/0/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        commit('SET_USER', data);
        commit('SET_IS_AUTHENTICATED', true);
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: true, data };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return Promise.reject();
      });
  },

  updateCurrentUser: ({ commit }, { user }) => {
    const token = localStorage.getItem('access-token');

    commit('UPDATE_IS_LOADING', true, { root: true });
    commit('CLEAR_ERRORS', true, { root: true });

    const {
      firstName,
      lastName,
      city,
      state,
      country,
      showEmail,
      link,
      bio,
      profilePicture,
      previousFirstName,
      previousLastName,
      employer,
      isDeceased,
      major,
      school,
      hideWelcomeBanner,
      socialMedia,
      streetAddress,
      phoneNumber,
    } = user;
    const finalData = {};
    finalData.firstName = firstName;
    finalData.lastName = lastName;
    finalData.city = city;
    finalData.state = state;
    finalData.country = country;
    finalData.showEmail = showEmail;
    finalData.bio = bio;
    finalData.employer = employer;
    finalData.major = major;
    finalData.isDeceased = isDeceased;
    finalData.school = school;
    finalData.streetAddress = streetAddress;
    finalData.hideWelcomeBanner = hideWelcomeBanner;
    finalData.phoneNumber = phoneNumber;

    finalData.previousFirstName = previousFirstName || '';
    finalData.previousLastName = previousLastName || '';
    finalData.link = link || '';

    let finalSocial = null;
    if (socialMedia) {
      finalSocial = [];
      socialMedia.forEach((element) => {
        finalSocial.push({ media_type: element.mediaType, url: element.url });
      });
    }

    const form_data = new FormData();
    const isUploadProfilePicture = profilePicture && typeof profilePicture !== 'string';

    if (isUploadProfilePicture) {
      createFormData(finalData, form_data);
      if (finalSocial) {
        form_data.append('user', `{"social_media":${JSON.stringify(finalSocial)}}`);
      }

      form_data.append('profilePicture', profilePicture, 'profilephoto.jpg');
    } else {
      finalData.user = { socialMedia: finalSocial };
    }

    return api
      .patch('api/v1/profiles/0/', isUploadProfilePicture ? form_data : finalData, {
        headers: {
          'Content-Type': isUploadProfilePicture ? 'multipart/form-data' : 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return {
          success: true,
          profile: data,
        };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },

  fetchNotifications: ({ commit }, token) => {
    return api
      .get('api/v1/notifications/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        commit('SET_NOTIFICATIONS', data.results);
        return { success: true, data };
      })
      .catch(() => {
        return Promise.reject();
      });
  },

  updateNotificationId: ({ commit }, notificationId) => {
    commit('SET_NOTIFICATION_ID', notificationId, { root: true });
  },

  markNotificationsRead: ({ commit }) => {
    const token = localStorage.getItem('access-token');

    return api
      .get('api/v1/notifications/mark_as_read/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        commit('SET_NOTIFICATIONS', data);
        return { success: true, data };
      })
      .catch(() => {
        return Promise.reject();
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
