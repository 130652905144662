import Vue from 'vue';
import VueCroppie from 'vue-croppie';
import Clipboard from 'v-clipboard';
import VueZoomer from 'vue-zoomer';
import VueGtag from 'vue-gtag';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import clickOutside from '@/directives/clickOutside';
// eslint-disable-next-line import/no-extraneous-dependencies
import VTooltip from 'v-tooltip';

import VueTheMask from 'vue-the-mask';

import ToggleButton from 'vue-js-toggle-button';
// eslint-disable-next-line import/no-extraneous-dependencies
import Vuelidate from 'vuelidate';
import App from './App.vue';
import router from './router';
import { store } from './store';
import '@/css/index.css';
import { pluralize, readableDate, highlightText, timeAgo, formatToMegabytes } from './filters';

Vue.use(Vuelidate);
Vue.use(VueZoomer);
Vue.use(VTooltip);
Vue.use(ToggleButton);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [
      new VueIntegration({
        Vue,
        attachProps: true,
      }),
    ],
  });
}

Vue.use(VueCroppie);
Vue.use(Clipboard);
Vue.use(VueTheMask);
Vue.use(VueGtag, {
  config: { id: 'UA-141051133-1' },
});

Vue.config.productionTip = false;

Vue.directive('click-outside', clickOutside);

Vue.filter('pluralize', pluralize);
Vue.filter('readableDate', readableDate);
Vue.filter('highlight', highlightText);
Vue.filter('timeAgo', timeAgo);
Vue.filter('formatToMegabytes', formatToMegabytes);

navigator.serviceWorker.getRegistrations().then((registrations) => {
  for (const registration of registrations) {
    registration.unregister();
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
