/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */
import { DateTime, Duration } from 'luxon';

export function createFormData(obj, existingFormData = new FormData()) {
  const formData = existingFormData;

  const createFormData = (obj, subKeyStr = '') => {
    for (const i in obj) {
      const value = obj[i];
      const subKeyStrTrans = subKeyStr ? `${subKeyStr}[${i}]` : i;

      if (typeof (value) === 'string' || typeof (value) === 'number' || typeof (value) === 'boolean') {
        formData.append(subKeyStrTrans, value);
      } else if (typeof (value) === 'object') {
        createFormData(value, subKeyStrTrans);
      }
    }
  };

  createFormData(obj);

  return formData;
}

export function validateEmail(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function formatUrl(url) {
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }
  return `https://${url}`;
}

export function determineSocialMediaLink(linkOrUsername, socialMediaId) {
  const isUsername = linkOrUsername.startsWith('@');
  if (isUsername) {
    linkOrUsername = linkOrUsername.substring(1);
  }
  switch (socialMediaId) {
    case 1: // instagram
      if (isUsername) {
        return `https://www.instagram.com/${linkOrUsername}`;
      }

      return formatUrl(linkOrUsername);
    case 2: // facebook
      if (isUsername) {
        return `https://www.facebook.com/${linkOrUsername}`;
      }
      return formatUrl(linkOrUsername);

    case 3: // linkedin
      if (isUsername) {
        return `https://www.linkedin.com/in/${linkOrUsername}`;
      }
      return formatUrl(linkOrUsername);

    case 4: // twitter
      if (isUsername) {
        return `https://www.twitter.com/${linkOrUsername}`;
      }
      return formatUrl(linkOrUsername);
    default:
      return formatUrl(linkOrUsername);
  }
}


export function groupByArray(xs, key) {
  return xs.reduce((rv, x) => {
    const v = key instanceof Function ? key(x) : x[key];
    const el = rv.find((r) => r && r.key === v);

    if (el) {
      el.values.push(x);
    } else {
      rv.push({ key: v, values: [x] });
    }

    return rv;
  }, []);
}

export function filterRsvps(list, searchValue) {
  if (!list.length) return [];
  if (searchValue === '') {
    return list;
  }
  return list
    .filter((profile) => {
      const fullName = `${profile.firstName.toLowerCase()} ${profile.lastName.toLowerCase()}`;
      return profile.firstName.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
        profile.lastName.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
        fullName.indexOf(searchValue.toLowerCase()) >= 0 ||
        profile.email.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0;
    });
}


export function timelineLabels(desiredStartTime, interval, period) {
  const periodsInADay = Duration.fromObject({ day: 1 }).as(period);

  const timeLabels = [];
  let startTimeMoment = DateTime.fromFormat(desiredStartTime, 'hh:mm');
  for (let l = 0; l <= periodsInADay - 1; l += interval) {
    startTimeMoment = startTimeMoment.plus(l === 0 ? 0 : { [period]: interval });
    timeLabels.push(startTimeMoment.toFormat('h:mm a'));
  }

  return timeLabels;
}

export function generateUUID() { // Public Domain/MIT
  let d = new Date().getTime();// Timestamp
  let d2 = (performance && performance.now && (performance.now() * 1000)) || 0;// Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16;// random number between 0 and 16
    if (d > 0) { // Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else { // Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}


export function formatPhone(f) {
  const f_val = f.replace(/\D[^\.]/g, '');
  return `${f_val.slice(0, 3)}${f_val.slice(3, 6)}-${f_val.slice(6)}`;
}
