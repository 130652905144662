export default function (userDate) {
  const o = {
    sec: 1000,
    min: 60 * 1000,
    hr: 60 * 1000 * 60,
    d: 24 * 60 * 1000 * 60,
    wk: 7 * 24 * 60 * 1000 * 60,
    m: 30 * 24 * 60 * 1000 * 60,
    y: 365 * 24 * 60 * 1000 * 60,
  };

  const r = Math.round;
  let dir = ' ago';

  const pl = (v, n) => {
    return `${n}${v}${(n > 1 ? 's' : '')} ${dir}`;
  };

  let ts = Date.now() - userDate.getTime();

  let ii;

  if (ts < 0) {
    ts *= -1;
    dir = ' from now';
  }

  for (const i in o) {
    if (r(ts) < o[i]) return pl(ii || 'm', r(ts / (o[ii] || 1)));
    ii = i;
  }

  return pl(ii, r(ts / o[ii]));
}
