const state = {
  globalErrors: [],
};

const getters = {
  globalErrors: ({ globalErrors }) => globalErrors,
};

const mutations = {
  CLEAR_ERRORS: (state, payload) => {
    if (!payload) return;

    state.globalErrors = [];
  },
  UPDATE_GLOBAL_ERRORS: (state, payload) => {
    state.globalErrors = payload;
  },
};

const actions = {
  clearGlobalErrors: ({ commit }) => {
    commit('CLEAR_ERRORS', true);
  },
};

export default { state, getters, actions, mutations };
