import error from './error';
import authentication from './authentication';
import user from './user';
import group from './group';
import superAdmin from './superAdmin';
import groupEvents from './groupEvents';
// eslint-disable-next-line import/extensions
import video from './video';

export default {
  error,
  authentication,
  user,
  group,
  video,
  superAdmin,
  groupEvents,
};
