// import qs from 'qs';
import { createFormData, generateUUID } from '../../helpers';
import api from '../api';

const state = {
  currentEvent: {},
  groupEvents: [],
  attendeesList: [],
  eventDiscussions: [],
  notificationId: null,
};

const getters = {
  currentEvent: ({ currentEvent }) => currentEvent,
  groupEvents: ({ groupEvents }) => groupEvents,
  attendeesList: ({ attendeesList }) => attendeesList,
  eventDiscussions: ({ eventDiscussions }) => eventDiscussions,
  notificationId: ({ notificationId }) => notificationId,
};

const mutations = {
  SET_EVENT: (state, payload) => {
    state.currentEvent = payload;
  },
  SET_GROUP_EVENTS: (state, payload) => {
    state.groupEvents = payload;
  },
  SET_EVENT_ATTENDEES: (state, payload) => {
    state.attendeesList = payload;
  },
  SET_EVENT_DISCUSSIONS: (state, payload) => {
    state.eventDiscussions = payload;
  },
  SET_NOTIFICATION_ID: (state, payload) => {
    state.notificationId = payload;
  },
};

const actions = {
  createGroupEvent: ({ commit }, { groupSlug, groupEvent }) => {
    const token = localStorage.getItem('access-token');
    commit('UPDATE_IS_LOADING', true, { root: true });
    return api
      .post(`api/v1/groups/${groupSlug}/group-events/`, groupEvent, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        commit('SET_EVENT', data);
        return { success: true, groupEvent: data };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },
  fetchGroupEvent: ({ commit }, { groupSlug, eventId }) => {
    const token = localStorage.getItem('access-token');
    commit('UPDATE_IS_LOADING', true, { root: true });
    return api
      .get(`api/v1/groups/${groupSlug}/group-events/${eventId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        commit('SET_EVENT', data);
        return { success: true, groupEvent: data };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },

  fetchGroupEventAttendees: ({ commit }, { groupSlug, eventId, filterIsOnMyList = false }) => {
    const token = localStorage.getItem('access-token');

    let url = `api/v1/groups/${groupSlug}/group-events/${eventId}/event-attendees/`;
    if (filterIsOnMyList) {
      url += '?is_on_my_list=1';
    }

    return api
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        commit('SET_EVENT_ATTENDEES', data.results);
        return { success: true, groupEvent: data.results };
      })
      .catch(() => {
        return { success: false };
      });
  },

  fetchEventList: ({ commit }, { groupSlug }) => {
    const token = localStorage.getItem('access-token');
    commit('UPDATE_IS_LOADING', true, { root: true });
    return api
      .get(`api/v1/groups/${groupSlug}/group-events/`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        commit('SET_GROUP_EVENTS', data.results);
        return { success: true, groupEvents: data.results };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },

  postRsvp: ({ commit }, { groupSlug, eventId, rsvp, userId, guests = 0 }) => {
    const post = {
      rsvp,
      guests_amount: guests,
    };
    if (userId) {
      post.profile = userId;
    }
    const token = localStorage.getItem('access-token');
    return api
      .post(`api/v1/groups/${groupSlug}/group-events/${eventId}/event-attendees/`, post, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        return { success: true, rsvp: data };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },
  updateEvent: ({ commit }, { groupSlug, eventId, groupEvent }) => {
    const token = localStorage.getItem('access-token');
    return api
      .put(`api/v1/groups/${groupSlug}/group-events/${eventId}`, groupEvent, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        commit('SET_EVENT', data);
        return { success: true, groupEvent: data };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },
  updateEventPhoto: ({ commit }, { groupSlug, eventId, photoBlob }) => {
    const formData = new FormData();
    formData.append('photo', photoBlob, 'eventPhoto.jpg');
    const token = localStorage.getItem('access-token');
    return api
      .put(`api/v1/groups/${groupSlug}/group-events/${eventId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        commit('SET_EVENT', data);
        return { success: true, groupEvent: data };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },
  fetchEventDiscussions: ({ commit }, { groupSlug, eventId }) => {
    const token = localStorage.getItem('access-token');
    return api
      .get(`api/v1/groups/${groupSlug}/group-events/${eventId}/event-discussions/`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        const ordered = data.results.sort((a, b) => {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.postedDatetime) - new Date(a.postedDatetime);
        });
        commit('SET_EVENT_DISCUSSIONS', ordered);
        return { success: true, discussions: ordered };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },

  addEventDiscussion: ({ commit, dispatch }, { groupSlug, eventId, post }) => {
    const token = localStorage.getItem('access-token');
    let blob;
    if (post.photo) {
      blob = post.photo;
      delete post.photo;
    }
    if (post.photo && !post.content) {
      post.content = '';
    }
    const formData = createFormData(post);
    if (blob) {
      formData.append('photo', blob, `discussionPhoto_${generateUUID()}.jpg`);
    }
    return api
      .post(`api/v1/groups/${groupSlug}/group-events/${eventId}/event-discussions/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        return dispatch('fetchEventDiscussions', { groupSlug, eventId });
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },

  deleteEventDiscussion: ({ commit, dispatch }, { groupSlug, eventId, postId }) => {
    const token = localStorage.getItem('access-token');
    return api
      .delete(`api/v1/groups/${groupSlug}/group-events/${eventId}/event-discussions/${postId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        return dispatch('fetchEventDiscussions', { groupSlug, eventId });
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },

  deleteGroupEvent: ({ commit }, { groupSlug, eventId }) => {
    commit('UPDATE_IS_LOADING', true, { root: true });
    const token = localStorage.getItem('access-token');
    return api
      .delete(`api/v1/groups/${groupSlug}/group-events/${eventId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: true };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },

  likePost: ({ commit, dispatch }, { groupSlug, eventId, postId }) => {
    const token = localStorage.getItem('access-token');
    return api
      .post(
        `api/v1/groups/${groupSlug}/group-events/${eventId}/event-discussions/${postId}/like`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(() => {
        return dispatch('fetchEventDiscussions', { groupSlug, eventId });
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },

  unlikePost: ({ commit, dispatch }, { groupSlug, eventId, postId }) => {
    const token = localStorage.getItem('access-token');
    return api
      .delete(
        `api/v1/groups/${groupSlug}/group-events/${eventId}/event-discussions/${postId}/like`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(() => {
        return dispatch('fetchEventDiscussions', { groupSlug, eventId });
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
