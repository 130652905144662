<template>
  <header
    v-click-outside="closeMenu"
    class="flex h-app-header justify-center bg-white shadow-small no-print">

    <button
      @click="toggleMenu"
      class="relative flex items-center justify-center
             text-lg pl-6 pr-3 bg-white rounded
             md:hidden
             hover:text-primary
             focus:text-primary
             transition-colors animation-focus-default"
      :class="[
        {'z-20' : isMenuOpen },
      ]">
      <MenuIcon v-if="!isMenuOpen" class="w-5 mr-3"></MenuIcon>
      <CloseIcon v-if="isMenuOpen" class="w-5 mr-3"></CloseIcon>
    </button>

    <template>
      <div class="flex w-full pr-2 lg:px-8 justify-between items-center">
        <div class="w-full flex justify-center pr-3 lg:justify-start lg:w-auto">
          <a
            href="https://reunacy.com/"
            target="_blank"
            rel="noopener noreferrer">
            <LargeLogo></LargeLogo>
          </a>
        </div>

        <Navigation
          @menuClosed="isMenuOpen = $event"
          :isMenuOpen="isMenuOpen"
          :neutralNavigation="true"
        />
      </div>
    </template>

    <!--TODO: remove when updates are ready-->

    <!--    <template v-else-if="showGroupHeaderWithNeutralNav">-->
    <!--      <div class="bg-primary w-full">-->
    <!--        <div class="flex justify-between w-full max-w-screen-xl mx-auto pt-1 pb-2 px-8">-->
    <!--          <div class="flex">-->
    <!--            <span class="text-white opacity-75 text-xs mr-2 text-sans font-bold">-->
    <!--              Powered by-->
    <!--            </span>-->
    <!--            <a-->
    <!--              href="https://reunacy.com/"-->
    <!--              target="_blank"-->
    <!--              rel="noopener noreferrer">-->
    <!--              <SmallLogo/>-->
    <!--            </a>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="flex justify-between px-8 w-full max-w-screen-xl mx-auto pt-4">-->
    <!--        <div class="flex flex-col">-->
    <!--          <h1 class="font-serif text-primary text-2xl">{{ group.name }}</h1>-->
    <!--          <h2 class="text-gray-600">{{ group.subtitle }}</h2>-->
    <!--        </div>-->
    <!--        <template v-if="showNavigation">-->
    <!--          <Navigation :neutralNavigation="true"/>-->
    <!--        </template>-->
    <!--      </div>-->
    <!--    </template>-->


    <!--    <template v-else-if="showGroupHeader">-->
    <!--      <div class="bg-primary w-full">-->
    <!--        <div class="flex justify-between w-full max-w-screen-xl mx-auto pt-1 pb-2 px-8">-->
    <!--          <router-link-->
    <!--            v-if="isAuthenticated"-->
    <!--            to="/group"-->
    <!--            class="flex items-center opacity-75 text-white text-xs text-sans font-bold">-->
    <!--            <ArrowLeft class="mr-2"/>-->
    <!--            <span>View All Groups</span>-->
    <!--          </router-link>-->
    <!--          <div class="flex">-->
    <!--            <span class="text-white opacity-75 text-xs mr-2 text-sans font-bold">-->
    <!--              Powered by-->
    <!--            </span>-->
    <!--            <a-->
    <!--              href="https://reunacy.com/"-->
    <!--              target="_blank"-->
    <!--              rel="noopener noreferrer">-->
    <!--              <SmallLogo/>-->
    <!--            </a>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="flex justify-between px-8 w-full max-w-screen-xl mx-auto pt-4">-->
    <!--        <router-link-->
    <!--          :to="`/group/${group.slug}/members`"-->
    <!--          class="focus:outline-none flex flex-col">-->
    <!--          <h1 class="font-serif text-primary text-2xl">{{ group.name }}</h1>-->
    <!--          <h2 class="text-gray-600">{{ group.subtitle }}</h2>-->
    <!--        </router-link>-->
    <!--        <template v-if="showNavigation">-->
    <!--          <Navigation-->
    <!--            :showProfileReminder="showProfileReminder"-->
    <!--            :showInviteButtonReminder="showInviteButtonReminder"-->
    <!--            :showGroupReminder="showGroupReminder"/>-->
    <!--        </template>-->
    <!--      </div>-->
    <!--    </template>-->


    <!--    <template v-else-if="!isAuthenticated">-->
    <!--      <div class="flex w-full max-w-screen-xl mx-auto px-4-->
    <!--                  sm:px-8 py-4 justify-between items-center">-->
    <!--        <a-->
    <!--          href="https://reunacy.com/"-->
    <!--          target="_blank"-->
    <!--          rel="noopener noreferrer">-->
    <!--          <LargeLogo/>-->
    <!--        </a>-->
    <!--        <template v-if="showNavigation">-->
    <!--          <Navigation neutralNavigation/>-->
    <!--        </template>-->
    <!--      </div>-->
    <!--    </template>-->

  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Navigation from '@/components/Navigation.vue';
import MenuIcon from '@/assets/icon_menu.svg';
import CloseIcon from '@/assets/icon_close.svg';
import LargeLogo from '@/assets/main-logo.svg';

export default {
  name: 'Header',
  components: {
    Navigation,
    MenuIcon,
    CloseIcon,
    LargeLogo,
  },
  computed: {
    ...mapGetters(['group', 'isAuthenticated', 'user']),
    // TODO: remove methods after all updates
    showGroupHeader() {
      return (this.isAuthenticated || this.allowedNoLoginRoutes.indexOf(this.routeName) !== -1) &&
        this.superAdminRoutes.indexOf(this.routeName) === -1;
    },
    showGroupHeaderWithNeutralNav() {
      /* signing up requires group info in the header but restricted nav access
      until profile is finished
       */
      return this.groupAndNeutralNavRoutes.indexOf(this.routeName) !== -1;
    },
    showNeutralHeader() {
      return this.isAuthenticated && this.neutralHeaderRoutes.indexOf(this.routeName) !== -1;
    },
    showNavigation() {
      return this.navigationBlacklist.indexOf(this.routeName) === -1;
    },
  },
  data() {
    return {
      navigationBlacklist: ['login'],
      neutralHeaderRoutes: ['create-group', 'groups', 'user-profile', 'super-admin'],
      groupAndNeutralNavRoutes: ['signup'],
      allowedNoLoginRoutes: ['members'],
      superAdminRoutes: ['super-admin'],
      isMenuOpen: false,
    };
  },
  methods: {
    closeMenu() {
      this.isMenuOpen = false;
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
  props: {
    /*
     * Name of current route
     */
    routeName: {
      type: String,
      default: '',
    },
    /*
 * whether red dot should show as reminder to edit profile info
 */
    showProfileReminder: {
      type: Boolean,
      default: false,
    },
    /*
     * whether red dot should show as reminder to click invite button
     */
    showInviteButtonReminder: {
      type: Boolean,
      default: false,
    },
    /*
     * whether red dot should show as reminder to edit group info
     */
    showGroupReminder: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    $route() {
      this.closeMenu();
    },
    isMenuOpen(value) {
      if (value) {
        document.querySelector('body').style.overflow = 'hidden';
      } else {
        document.querySelector('body').style.overflow = 'auto';
      }
    },
  },
};
</script>

<style lang="scss">
header {
  z-index: 99;
}
</style>
