import axios from 'axios';
import qs from 'qs';
import api from '../api';

const state = {
  videoPlayerOpen: false,
  prompts: null,
};

const getters = {
  videoPlayerOpen: ({ videoPlayerOpen }) => videoPlayerOpen,
  prompts: ({ prompts }) => prompts,
};

const mutations = {
  UPDATE_VIDEO_PLAYER: (state, payload) => {
    state.videoPlayerOpen = payload;
  },
  UPDATE_GROUP_PROMPTS: (state, payload) => {
    state.prompts = payload;
  },
};

const actions = {
  openVideoPlayer: ({ commit }) => {
    commit('UPDATE_VIDEO_PLAYER', true);
  },
  closeVideoPlayer: ({ commit }) => {
    commit('UPDATE_VIDEO_PLAYER', false);
  },
  fetchVideoWithTaskId: ({ commit }, taskId) => {
    const token = localStorage.getItem('access-token');
    commit('UPDATE_IS_LOADING', true, { root: true });
    return api
      .get(`api/v1/tasks/${taskId}/`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return {
          success: data.success,
          data,
        };
      })
      .catch((error) => {
        const shouldRefetch = error.toString().indexOf('404') >= 0;
        return {
          success: false,
          error,
          shouldRefetch,
        };
      });
  },
  uploadVideo: ({ commit }, options) => {
    const token = localStorage.getItem('access-token');
    commit('UPDATE_IS_LOADING', true, { root: true });
    const finalData = new FormData();
    finalData.append('s3Key', options.s3Key);
    finalData.append('promptId', options.promptID);
    finalData.append('isVisible', options.isVisible);

    return api
      .post(`api/v1/groups/${options.groupSlug}/videos/`, finalData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        // don't update global loading state
        // fetchVideoWithTaskId needs to complete before it's done "loading" in the UI
        return { success: true, taskId: data.taskId };
      })
      .catch((error) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false, error };
      });
  },
  fetchVideo: ({ commit }, { groupSlug, videoId }) => {
    const token = localStorage.getItem('access-token');

    commit('UPDATE_IS_LOADING', true, { root: true });
    return api
      .get(`api/v1/groups/${groupSlug}/videos/${videoId}`, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: true, video: data };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },

  updateVideoPrivacy: ({ commit }, { groupSlug, videoId, isVisible }) => {
    const token = localStorage.getItem('access-token');

    commit('UPDATE_IS_LOADING', true, { root: true });
    return api
      .patch(
        `api/v1/groups/${groupSlug}/videos/${videoId}/`,
        {
          isVisible,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: true };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },
  deleteUserVideo: ({ commit }, { groupSlug, videoId }) => {
    const token = localStorage.getItem('access-token');

    commit('UPDATE_IS_LOADING', true, { root: true });
    return api
      .delete(`api/v1/groups/${groupSlug}/videos/${videoId}/`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: true };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },
  fetchGroupVideoPrompts: ({ commit }, groupSlug) => {
    const token = localStorage.getItem('access-token');

    commit('UPDATE_IS_LOADING', true, { root: true });
    return api
      .get(`api/v1/groups/${groupSlug}/prompts/`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        commit('UPDATE_GROUP_PROMPTS', data.results);
        return { success: true, prompts: data.results };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },
  createGroupVideoPrompt: ({ commit }, { groupSlug, prompt, isVisible }) => {
    const token = localStorage.getItem('access-token');

    commit('UPDATE_IS_LOADING', true, { root: true });
    return api
      .post(
        `api/v1/groups/${groupSlug}/prompts/`,
        { prompt, isVisible },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: true, res };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },
  deleteGroupVideoPrompt: ({ commit }, { groupSlug, promptId }) => {
    const token = localStorage.getItem('access-token');

    commit('UPDATE_IS_LOADING', true, { root: true });
    // We don't actually delete the prompt to preserve it for any existing videos (just gets hidden)
    return api
      .patch(
        `api/v1/groups/${groupSlug}/prompts/${promptId}/`,
        {
          isVisible: false,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: true };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },
  updateGroupVideoPrompt: ({ commit }, { prompt, groupSlug, promptId }) => {
    const token = localStorage.getItem('access-token');

    commit('UPDATE_IS_LOADING', true, { root: true });
    return api
      .patch(
        `api/v1/groups/${groupSlug}/prompts/${promptId}/`,
        {
          prompt,
          isVisible: true,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: true };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },
  retrieveS3URL({ dispatch, commit }, { fileName, fileType, file }) {
    const token = localStorage.getItem('access-token');

    commit('UPDATE_IS_LOADING', true, { root: true });
    return api
      .get(
        `api/v1/videos/sign_s3_url/?${qs.stringify(
          { file_name: fileName, file_type: fileType },
          { strictNullHandling: true },
        )}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async ({ data }) => {
        return dispatch('uploadToS3', {
          data,
          file,
        });
      })
      .catch(() => {
        return { success: false };
      })
      .finally(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
      });
  },
  uploadToS3(_, { data, file }) {
    const s3Data = new FormData();
    s3Data.append('key', data.fields.key);
    s3Data.append('AWSAccessKeyId', data.fields.aWSAccessKeyId);
    s3Data.append('policy', data.fields.policy);
    s3Data.append('signature', data.fields.signature);
    s3Data.append('file', file);

    return axios
      .post(`${data.url}`, s3Data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        return { success: true, videoLink: data.fields.key };
      })
      .catch((error) => {
        return { success: false, error };
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
