import qs from 'qs';
import { constants } from '../../config';
import api from '../api';

const state = {
  authenticationError: null,
  isAuthenticated: false,
  linkSent: false,
  passwordResetConfirmed: false,
};

const getters = {
  authenticationError: ({ authenticationError }) => authenticationError,
  linkSent: ({ linkSent }) => linkSent,
  passwordResetConfirmed: ({ passwordResetConfirmed }) => passwordResetConfirmed,
  isAuthenticated: ({ isAuthenticated }) => isAuthenticated,
};

const mutations = {
  CLEAR_STATE: (state) => {
    state.authenticationError = null;
    state.isAuthenticated = false;
    state.linkSent = false;
    state.passwordResetConfirmed = false;
  },
  SET_IS_AUTHENTICATED: (state, payload) => {
    state.isAuthenticated = payload;
  },
  FAILED_AUTH_ACTION: (state, payload) => {
    state.authenticationError = payload;
  },
  SET_LINK_SENT: (state, payload) => {
    state.linkSent = payload;
  },
  PASSWORD_RESET_CONFIRMED: (state, payload) => {
    state.passwordResetConfirmed = payload;
  },
};

const actions = {
  registerUser: ({ commit }, { groupSlug, user }) => {
    commit('UPDATE_IS_LOADING', true, { root: true });
    commit('CLEAR_ERRORS', true, { root: true });
    const {
      firstName,
      lastName,
      password,
    } = user;
    let email;
    if (user && user.email) {
      email = user.email.toLowerCase();
    }

    const finalData = {
      firstName,
      lastName,
      email,
      password,
    };

    return api.post(`api/v1/groups/${groupSlug}/signup/`, finalData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        commit('SET_USER', data, { root: true });
        return { success: true };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },

  login: ({ commit }, user) => {
    commit('UPDATE_IS_LOADING', true, { root: true });

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${constants.BASIC_AUTH}`,
      },
    };

    const requestBody = {
      grant_type: 'password',
      username: user.email.toLowerCase(),
      password: user.password,
    };

    return api.post('api/v1/oauth/token/', qs.stringify(requestBody), config)
      .then(({ data }) => {
        localStorage.setItem('access-token', data.accessToken);
        commit('UPDATE_IS_LOADING', false, { root: true });
        commit('SET_IS_AUTHENTICATED', true);
        return { success: true };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },

  logout: ({ commit }) => {
    commit('CLEAR_STATE');
    localStorage.clear();
    return new Promise((resolve) => resolve());
  },

  showAuthError: ({ commit }, errorMessage) => {
    commit('FAILED_AUTH_ACTION', errorMessage);
  },

  clearAuthError: ({ commit }) => {
    commit('FAILED_AUTH_ACTION', null);
  },

  updateLinkSentStatus: ({ commit }, status) => {
    commit('SET_LINK_SENT', status);
  },

  updatePasswordResetStatus: ({ commit }, status) => {
    commit('PASSWORD_RESET_CONFIRMED', status);
  },

  sendForgotPasswordLink: ({ commit }, email) => {
    commit('UPDATE_IS_LOADING', true, { root: true });

    return api.post('/api/v1/password/reset/', { email })
      .then(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        commit('SET_LINK_SENT', true);
        commit('FAILED_AUTH_ACTION', null);
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        commit('SET_LINK_SENT', false);
        commit('FAILED_AUTH_ACTION', 'An email could not be sent. Please try again.');
      });
  },

  resetPassword: ({ commit }, { pw, token, uid }) => {
    const requestBody = {
      new_password1: pw,
      new_password2: pw,
      token,
      uid,
    };
    commit('UPDATE_IS_LOADING', true, { root: true });

    return api.post('/api/v1/password/reset/confirm/', requestBody)
      .then(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        commit('PASSWORD_RESET_CONFIRMED', true);
        commit('FAILED_AUTH_ACTION', null);
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        commit('PASSWORD_RESET_CONFIRMED', false);
        commit('FAILED_AUTH_ACTION', 'Your password could not be reset. Please try again.');
      });
  },

  checkUsernameExists: ({ commit }, username) => {
    commit('CLEAR_ERRORS', true, { root: true });
    return api.get(`/api/v1/username-exists?username=${username}`).then(({ data }) => {
      return { success: true, data };
    }).catch(() => {
      return { success: false };
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
