import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';

Vue.use(Vuex);

export const state = {
  isLoading: false,
};

export const getters = {
  isLoading: ({ isLoading }) => isLoading,
};

export const mutations = {
  UPDATE_IS_LOADING: (state, payload) => {
    state.isLoading = payload;
  },
};

export const store = new Vuex.Store({
  state,
  getters,
  mutations,
  modules,
});
