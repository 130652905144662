<template>
  <nav class="flex">
    <!--                 -->
    <!-- TABS NAVIGATION -->
    <!--                 -->

    <ul
      class="hidden lg:block nav-items-container absolute left-0 h-full z-10 text-xl text-gray-600 py-20 px-8 md:p-0 md:flex md:text-base md:relative mr-10"
      :class="{ hidden: !isMenuOpen }"
    >
      <li
        v-if="authenticatedWithCompletedProfile"
        class="flex text-left md:text-center mb-8 items-center md:mb-0 w-max"
      >
        <router-link
          to="/group"
          class="inline-block w-full hover:text-primary focus:text-primary focus:outline-none transition-colors duration-200 ease-out"
        >
          <h6 class="underlinable inline-block pb-6">My Groups</h6>
        </router-link>
      </li>

      <li class="md:hidden inline-block" v-if="isAuthenticated">
        <button
          class="inline-block w-full hover:text-primary focus:text-primary transition-colors animation-focus-default"
          @click="handleLogout"
        >
          Logout
        </button>
      </li>
    </ul>

    <!--             -->
    <!-- SUPER ADMIN -->
    <!--             -->

    <router-link
      v-if="authenticatedWithCompletedProfile && isSuperAdmin"
      to="/super-admin"
      class="hidden lg:block transition-colors duration-200 ease-out mr-2"
    >
      <button class="nav-icon-btn animation-focus-default">
        <SettingsIcon class="nav-icon" />
      </button>
    </router-link>

    <!--                        -->
    <!-- NOTIFICATIONS DROPDOWN -->
    <!--                        -->

    <button
      v-show="authenticatedWithCompletedProfile"
      @click="toggleNotifications"
      v-click-outside="closeNotificationsMenu"
      class="nav-icon-btn animation-focus-default lg:mr-8 relative"
    >
      <NotificationsIcon class="nav-icon" />
      <span
        v-if="!everyNotificationSeen()"
        class="dot-icon absolute bg-primary rounded rounded-full"
      ></span>
    </button>

    <div
      :class="{
        'dropdown-hidden pointer-events-none': !notificationsMenuVisible,
      }"
      class="notifications-dropdown fixed sm:absolute top-app-header -mt-1 overflow-hidden sm:-m-2 right-3 sm:right-4 lg:right-136 left-3 sm:left-auto bg-white rounded sm:shadow-md sm:rounded sm:shadow-lg z-20"
    >
      <div class="pl-6 pr-2 py-5 w-auto sm:w-320px rounded border border-gray-300">
        <div class="border-b border-gray-300 mr-4">
          <h5 class="mb-3">Notifications</h5>
        </div>
        <ul id="notificationsContainer" class="overflow-y-auto custom-scroll max-h-notifications">
          <li class="w-full text-center pt-12 pb-7 text-small" v-if="!notifications.length">
            You have no new notifications
          </li>
          <li
            v-for="(notification, index) of notifications"
            :key="index"
            :id="'message-' + index"
            class="cursor-pointer mt-6 w-full rounded flex pr-4"
            @click="handleNotificationClick(notification)"
          >
            <div class="image-container relative mr-4">
              <div
                v-if="notification.notificationType"
                class="message-type absolute rounded-full bg-primary"
              >
                <HeartIcon v-if="notification.notificationType.id === 1" />
                <MessageIcon v-if="notification.notificationType.id === 2" />
              </div>
              <img
                class="inline-block min-w-9 w-9 min-h-9 h-9 bg-white shadow-md rounded-full"
                :src="
                  notification.actionUserProfile
                    ? notification.actionUserProfile.profilePicture
                    : notification.profile.profilePicture
                "
                alt="user_avatar"
              />
            </div>
            <div class="message-container -mt-1">
              <div
                v-if="notification.notificationType"
                class="header mb-1 flex text-sm font-semibold"
              >
                {{
                  `${
                    notification.actionUserProfile
                      ? notification.actionUserProfile.firstName
                      : notification.profile.firstName
                  } ${
                    notification.actionUserProfile
                      ? notification.actionUserProfile.lastName
                      : notification.profile.lastName
                  } `
                }}
                {{ `${formatNotificationText(notification.notificationType.id)} ` }}
              </div>
              <div class="info flex text-small">
                <div class="group text-gray-600 pr-3 border-r border-gray-300">
                  {{ notification.groupName }}
                </div>
                <div class="time text-gray-600 pl-3">
                  {{ getTimeAgo(notification.createdDatetime) }}
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!--                        -->
    <!-- LOGIN / LOGOUT BUTTONS -->
    <!--                        -->

    <div
      v-if="!isAuthenticated"
      class="flex text-left md:text-center items-center justify-center w-50px lg:w-auto"
    >
      <router-link
        to="/login"
        class="flex font-semibold hover:text-primary focus:text-primary focus:outline-none transition-colors duration-200 ease-out"
      >
        <UserIcon class="w-6 lg:mr-3" />
        <span class="hidden lg:flex mr-2">Login</span>
      </router-link>
    </div>

    <div
      v-if="isAuthenticated && !authenticatedWithCompletedProfile"
      class="hidden md:flex text-left md:text-center mb-8 items-center md:mb-0 md:mr-4 lg:mr-8"
    >
      <button
        class="cursor-pointer w-full flex hover:text-primary focus:text-primary focus:outline-none transition-colors duration-200 ease-out"
        @click="handleLogout"
      >
        <LogoutIcon class="w-6 mt-1 mr-3" />
        Log out
      </button>
    </div>

    <!--                      -->
    <!-- USER AVATAR DROPDOWN -->
    <!--                      -->

    <div
      v-show="authenticatedWithCompletedProfile"
      class="relative w-max flex text-left hidden md:block md:text-center items-center mb-8 md:mb-0"
    >
      <template v-if="showProfileReminder && !subMenuVisible">
        <RedDot class="absolute top-1 left-8" />
      </template>
      <button
        class="flex items-center w-full hover:text-primary animation-focus-default focus:text-primary focus:outline-none transition-colors duration-200 ease-out"
        @click="toggleMenu()"
        aria-haspopup="listbox"
        :aria-expanded="subMenuVisible"
        ref="user-dropdown"
        v-click-outside="closeSubMenu"
      >
        <span class="hidden md:inline-flex md:items-center">
          <img
            v-if="user && user.profilePicture"
            class="inline-block w-11 h-11 bg-white shadow-md mr-2 rounded-full"
            :src="user && user.profilePicture"
            alt="profile-picture"
          />
          <img
            v-else
            src="@/assets/profile_photo_outline.png"
            class="inline-block w-11 h-11 bg-white shadow-md mr-2 rounded-full"
            alt="profile-picture"
          />
          <span
            class="inline-block transition-transform duration-200 ease transform ml-2 mt-1"
            :class="{
              'rotate-180': subMenuVisible,
            }"
            aria-hidden="true"
          >
            <Chevron class="w-3" />
          </span>
        </span>
      </button>

      <div
        class="user-dropdown absolute overflow-hidden bg-white rounded w-48 right-0 mt-2 shadow-md z-20 inline-block min-w-full"
        role="listbox"
        ref="user-dropdown"
        :class="[{ 'dropdown-hidden pointer-events-none': !subMenuVisible }]"
      >
        <ul class="p-2 border border-gray-300 rounded">
          <li
            class="cursor-pointer px-3 py-2 text-sm text-left w-full whitespace-no-wrap hover:bg-gray-100 focus:outline-none focus:bg-gray-100 mb-1 animation-focus-default transition-colors duration-100 ease rounded flex items-center"
            role="option"
            @click="handleViewProfile"
          >
            <UserIcon class="w-4 mt-1 mr-2" />
            My Profile
          </li>
          <li
            class="cursor-pointer px-3 py-2 text-sm text-left w-full whitespace-no-wrap hover:bg-gray-100 focus:outline-none focus:bg-gray-100 mb-1 animation-focus-default transition-colors duration-100 ease rounded flex items-center"
            role="option"
            @click="handleSettings"
          >
            <SettingsIcon class="w-4 mr-2" />
            Settings
          </li>
          <li
            class="cursor-pointer px-3 py-2 text-sm text-left w-full whitespace-no-wrap hover:bg-gray-100 focus:outline-none focus:bg-gray-100 mb-1 animation-focus-default transition-colors duration-100 ease rounded flex items-center"
            role="option"
            @click="handleLogout"
          >
            <LogoutIcon class="w-4 mt-1 mr-2" />
            Log out
          </li>
        </ul>
      </div>
    </div>

    <!--                    -->
    <!-- MOBILE / IPAD MENU -->
    <!--                    -->

    <div
      class="menu-mobile shadow-small-inset lg:hidden fixed w-full sm:w-375px top-app-header bottom-0 left-0 sm:right-auto sm:left-0 bg-white"
      :class="{ 'menu-hidden': !isMenuOpen }"
    >
      <ul class="px-6 w-full sm:w-375px">
        <li
          @click="isMenuOpen = false"
          class="py-8 border-b border-gray-300 animation-focus-default"
        >
          <router-link
            to="/group"
            class="inline-block w-full hover:text-primary focus:text-primary focus:outline-none transition-colors duration-200 ease-out"
          >
            <h6 @click="isMenuOpen = false" class="menu-btn bg-white rounded p-4">My Groups</h6>
          </router-link>
        </li>
        <router-link to="/profile">
          <li
            v-if="isAuthenticated && user"
            @click="isMenuOpen = false"
            :class="{ 'opacity-100': isMenuOpen, 'opacity-0': !isMenuOpen }"
            class="flex text-left font-semibold pt-6 ml-4 items-center md:hidden animation-focus-default"
          >
            <img
              class="inline-block w-11 h-11 bg-white shadow-md mr-3 rounded-full"
              :src="user.profilePicture"
            />
            {{ user.firstName }} {{ user.lastName }}
          </li>
        </router-link>
        <li class="mt-4 animation-focus-default">
          <router-link
            to="/super-admin"
            @click="isMenuOpen = false"
            class="w-full hover:text-primary focus:text-primary focus:outline-none transition-colors duration-200 ease-out"
          >
            <div class="menu-btn bg-white rounded p-4 flex items-center">
              <SettingsIcon class="mr-2 w-4 h-4" />
              Settings
            </div>
          </router-link>
        </li>
        <li class="animation-focus-default">
          <router-link
            to="/profile"
            @click="isMenuOpen = false"
            class="w-full hover:text-primary focus:text-primary focus:outline-none transition-colors duration-200 ease-out"
          >
            <div class="menu-btn bg-white rounded p-4 flex items-center">
              <UserIcon class="mr-2 w-4 h-4" />
              My Profile
            </div>
          </router-link>
          <router-link
            to="/settings"
            @click="isMenuOpen = false"
            class="w-full hover:text-primary focus:text-primary focus:outline-none transition-colors duration-200 ease-out"
          >
            <div class="menu-btn bg-white rounded p-4 flex items-center">
              <SettingsIcon class="mr-2 w-4 h-4" />
              Settings
            </div>
          </router-link>
        </li>
        <li class="animation-focus-default">
          <div
            @click="handleLogout"
            class="w-full hover:text-primary focus:text-primary focus:outline-none transition-colors duration-200 ease-out cursor-pointer"
          >
            <div class="menu-btn bg-white rounded p-4 flex items-center">
              <LogoutIcon class="mr-2 w-4 h-4" />
              Log out
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Chevron from '@/assets/chevron.svg';
import SettingsIcon from '@/assets/icon_settings.svg';
import NotificationsIcon from '@/assets/icon_notifications.svg';
import UserIcon from '@/assets/icon_user.svg';
import HeartIcon from '@/assets/icon_heart_white.svg';
import MessageIcon from '@/assets/icon_message_white.svg';
import LogoutIcon from '@/assets/icon_logout.svg';
import RedDot from '@/components/RedDot.vue';
import { DateTime } from 'luxon';
import { DEFAULT_PROMPTS } from './constants/video';

export default {
  name: 'Navigation',
  components: {
    RedDot,
    Chevron,
    SettingsIcon,
    NotificationsIcon,
    UserIcon,
    LogoutIcon,
    HeartIcon,
    MessageIcon,
  },
  props: {
    /*
     * whether navigation menu triggered outside
     */
    isMenuOpen: {
      type: Boolean,
      default: false,
    },
    /*
     * whether red dot should show as reminder to edit profile info
     */
    showProfileReminder: {
      type: Boolean,
      default: false,
    },
    /*
     * whether red dot should show as reminder to click invite button
     */
    showInviteButtonReminder: {
      type: Boolean,
      default: false,
    },
    /*
     * whether red dot should show as reminder to edit group info
     */
    showGroupReminder: {
      type: Boolean,
      default: false,
    },
    menuClosed: {
      type: Function,
    },
  },
  created() {
    const token = localStorage.getItem('access-token');
    if (token) {
      this.fetchNotifications(token);
    }
  },
  updated() {
    if (this.isAdmin) {
      this.fetchGroupVideoPrompts(this.$route.params.group).then((res) => {
        this.handleFetchVideoResponse(res);
      });
    }
  },
  computed: {
    ...mapGetters(['group', 'isAuthenticated', 'user', 'notifications']),
    isSuperuser() {
      return !!this.user && !!this.user.user && !!this.user.user.isSuperuser;
    },
    isSuperAdmin() {
      return (
        !!this.user && !!this.user.user && !!this.user.user.isSuperuser && !!this.user.user.isStaff
      );
    },
    isAdmin() {
      if (this.$route.params.group && this.user && Object.keys(this.user).length) {
        return this.user.adminOfGroups.indexOf(this.$route.params.group) >= 0 || this.isSuperuser;
      }
      return false;
    },
    authenticatedWithCompletedProfile() {
      return !!this.isAuthenticated && !!this.user;
    },
    memberOfGroup() {
      return (
        this.user &&
        this.user.groups &&
        this.user.groups.some((group) => group.slug === this.$route.params.group)
      );
    },
    notAuthenticatedOrNotMember() {
      if (this.isSuperuser) return false;
      return !this.isAuthenticated || (this.isAuthenticated && !this.memberOfGroup);
    },
  },
  data() {
    return {
      subMenuVisible: false,
      notificationsMenuVisible: false,
      promptOptions: DEFAULT_PROMPTS,
      notificationsContainer: null,
    };
  },
  methods: {
    ...mapActions([
      'logout',
      'updateModalOpen',
      'createGroupVideoPrompt',
      'updateNotificationId',
      'fetchGroupVideoPrompts',
      'fetchNotifications',
      'markNotificationsRead',
    ]),
    handleFetchVideoResponse(res) {
      if (res.success && !res.prompts.length) {
        this.addDefaultVideoPrompts();
      }
    },
    addDefaultVideoPrompts() {
      this.promptOptions.forEach((prompt) => {
        this.createGroupVideoPrompt({
          groupSlug: this.$route.params.group,
          prompt,
        });
      });
    },
    handleJoinGroup() {
      this.$router.push({
        name: 'signup',
        params: {
          group: this.$route.params.group,
        },
      });
    },
    toggleMenu() {
      this.subMenuVisible = !this.subMenuVisible;
    },
    closeSubMenu() {
      this.subMenuVisible = false;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    toggleNotifications() {
      this.notificationsMenuVisible = !this.notificationsMenuVisible;
      this.isMenuOpen = false;

      this.notificationsContainer = document.getElementById('notificationsContainer');

      if (this.notificationsContainer && !this.everyNotificationSeen()) {
        if (this.notificationsMenuVisible) {
          this.markNotificationsRead();
        }
        // else {
        //   this.notificationsContainer.removeEventListener('scroll', this.handleNotificationsScroll);
        // }
      }
    },
    // handleNotificationsScroll() {
    //   this.notifications.forEach((el, index) => {
    //     if (!el.seen) {
    //       const message = document.getElementById(`message-${index}`);
    //       if (this.notificationsContainer.clientHeight + this.notificationsContainer.scrollTop > message.offsetTop - message.offsetHeight) {
    //         // todo: endpoint to set seen true
    //         el.seen = true;
    //       }
    //     }
    //   });
    // },
    everyNotificationSeen() {
      return this.notifications.every((el) => el.seen);
    },
    closeNotificationsMenu() {
      this.notificationsMenuVisible = false;
    },
    handleLogout() {
      // Google Analytics Event
      this.$gtag.event('logout', {
        event_category: 'navigation',
        event_label: 'User logged out',
      });

      this.logout().then(() => {
        this.$router
          .push({
            name: 'login',
          })
          .catch(() => {})
          .finally(() => {
            this.toggleMenu();
          });
      });
    },
    handleViewProfile() {
      this.$router
        .replace({
          name: 'user-profile',
          params: {
            user: this.user.slug,
            group: this.user.group,
          },
        })
        .catch(() => {})
        .finally(() => {
          this.toggleMenu();
        });
    },
    handleSettings() {
      this.$router
        .replace({
          name: 'settings',
        })
        .catch(() => {})
        .finally(() => {
          this.toggleMenu();
        });
    },
    showModal() {
      localStorage.setItem('invite-modal-opened', 'true');
      // Google Analytics Event
      this.$gtag.event('clicked_invite', {
        event_category: 'members',
        event_label: `${this.$route.params.group} / Clicked invite button`,
      });
      this.isMenuOpen = false;
      this.updateModalOpen(true);
    },
    hideModal() {
      this.updateModalOpen(false);
    },
    getTimeAgo(dateUTC) {
      if (dateUTC) {
        return DateTime.fromISO(dateUTC, { zone: 'utc' }).toRelative();
      }

      return '';
    },
    handleNotificationClick(notification) {
      let path = '';
      switch (notification.notificationType.id) {
        case 1:
        case 2:
          path = `/group/${notification.groupSlug}/events/${notification.eventId}`;
          break;
        case 3:
          path = `/group/${notification.groupSlug}/profile/${notification.actionUserProfile.slug}`;
          break;
        case 4:
          path = `/group/${notification.groupSlug}/events/${notification.eventId}`;
          break;
        default:
          return;
      }

      this.updateNotificationId(notification.discussionId);

      // TODO: fix updating event page if a route doesn't change static parts of the path
      // e.g. /group/groupSlug-1111/events/1 => /group/groupSlug-2222/events/49
      if (path !== this.$route.path) {
        this.$router.replace(path);
      }
    },
    formatNotificationText(notificationTypeId) {
      // `${notification.notificationType.id === 1 ? 'liked your post' : notification.notificationType.id === 2 ? 'commented on your post' : 'made something incredible'} `
      switch (notificationTypeId) {
        case 1:
          return 'liked your post';
        case 2:
          return 'commented on your post';
        case 3:
          return 'joined your group';
        case 4:
          return "RSVP'd to your event";
        default:
          return '';
      }
    },
  },
  watch: {
    isMenuOpen(value) {
      if (value) {
        document.querySelector('body').style.overflow = 'hidden';
      } else {
        document.querySelector('body').style.overflow = 'auto';
      }
      this.$emit('menuClosed', value);
    },
    $route() {
      this.closeMenu();
    },
  },
};
</script>

<style lang="scss">
.nav-items-container {
  bottom: -10px;
  margin-top: 1px;
}

.router-link-active > .nav-icon-btn {
  @apply bg-gray-200;
}

.nav-icon-btn {
  @apply rounded rounded-full h-12 p-4 bg-white;

  &:hover,
  &:focus {
    @apply bg-gray-200 outline-none;
  }

  .nav-icon {
    width: 19px;
    height: 19px;
    margin-top: -2px;
  }
}

.dot-icon {
  width: 6px;
  height: 6px;
  top: 9px;
  right: 12px;
}

.message-type {
  top: -4px;
  right: -6px;
  padding: 5px;

  svg {
    width: 9px;
    height: 9px;
  }
}

.menu-mobile {
  z-index: -1;
  transition: width 0.1s ease-in-out;

  &.menu-hidden {
    width: 0;
    overflow: hidden;
  }

  .router-link-active > .menu-btn {
    @apply bg-gray-100;
  }
}

.router-link-active > .underlinable {
  @apply text-primary relative;

  &::after {
    @apply absolute bottom-0 left-0 right-0 bg-primary;
    content: '';
    height: 3px;
  }
}

.user-dropdown {
  max-height: 190px;
  transition: max-height 0.2s ease-in-out;
}

.notifications-dropdown {
  max-height: 472px;
  transition: max-height 0.2s ease-in-out;
}

.dropdown-hidden {
  max-height: 0;
  transition: max-height 0.1s ease-in-out;
}
</style>
