<template>
  <div
    id="app"
    class="pt-app-header flex flex-col min-h-screen justify-start"
    :class="[{ 'h-screen overflow-hidden mb-0': inviteModalOpen || videoModalOpen }]"
  >
    <Header
      :routeName="routeName"
      :showGroupReminder="showGroupReminder"
      :showProfileReminder="showProfileReminder"
      :showInviteButtonReminder="showInviteButtonReminder"
      class="fixed w-full h-app-header top-0"
    />
    <template v-if="!notAuthenticatedOrNotMember && showBanner && onGroupPage">
      <WelcomeBanner
        :nextStepsComplete="nextStepsComplete"
        :key="`${user}-${group}-key`"
        :bannerType="welcomeBannerType"
        :hideBanner="preventBannerFromShowing"
      />
    </template>
    <div class="page-wrapper">
      <router-view />
    </div>
    <!--    <Footer/>-->
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import cssVars from 'css-vars-ponyfill';
import Header from '@/components/Header.vue';
import WelcomeBanner from '@/components/WelcomeBanner.vue';
import { DEFAULT_INTERACTIVE_COLOR, DEFAULT_COLOR } from '@/components/constants/groupColors';

import interceptor from './interceptors';
import { constants } from './config';

export default {
  name: 'App',
  components: {
    Header,
    WelcomeBanner,
  },
  data() {
    return {
      reunacyColor: DEFAULT_COLOR,
      primary: DEFAULT_COLOR,
      defaultInteractive: DEFAULT_INTERACTIVE_COLOR,
      hasAddedSnapPixelScript: false,
      showBanner: null,
      showGroupReminder: false,
      showProfileReminder: false,
      showInviteButtonReminder: false,
      nextStepsComplete: false,
      welcomeBannerType: {
        option1: {
          label: '',
          value: '',
        },
        option2: {
          label: '',
          value: '',
        },
        option3: {
          label: '',
          value: '',
        },
      },
    };
  },
  created() {
    interceptor(this);
    this.setPrimaryVar();
    this.checkForIncompleteProfile();

    const ckeditor = document.createElement('script');
    ckeditor.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');
    ckeditor.setAttribute('data-cb-site', constants.CHARGEBEE_SOURCE);
    document.head.appendChild(ckeditor);
  },
  watch: {
    $route() {
      this.updatePrimaryColor();
    },
    group() {
      if (this.group && this.group.primaryColor) {
        this.updatePrimaryColor();
      }
      if (this.group) {
        this.checkToShowWelcomeBanner();
      }
    },
    user() {
      this.checkAuthentication();
      this.handlePixelScript();
      this.checkToShowWelcomeBanner();
    },
    inviteModalOpen() {
      this.checkToShowWelcomeBanner();
    },
  },
  computed: {
    ...mapGetters(['group', 'user', 'isAuthenticated', 'inviteModalOpen', 'videoModalOpen']),
    routeName() {
      return this.$route.name;
    },
    memberOfGroup() {
      return (
        this.user &&
        this.user.groups &&
        this.user.groups.some((group) => group.slug === this.$route.params.group)
      );
    },
    notAuthenticatedOrNotMember() {
      if (this.isSuperuser) return false;
      return !this.isAuthenticated || (this.isAuthenticated && !this.memberOfGroup);
    },
    profileEdited() {
      return (
        this.user &&
        !!(
          this.user.city ||
          this.user.bio ||
          this.user.major ||
          this.user.employer ||
          this.user.link ||
          this.user.school
        )
      );
    },
    groupEdited() {
      return this.group && !!this.group.description;
    },
    onGroupPage() {
      return (
        !!this.$route.params.group &&
        this.$route.name !== 'signup' &&
        this.$route.name !== 'join-group'
      );
    },
  },
  methods: {
    ...mapActions(['fetchCurrentUser', 'updateCurrentUser']),
    handlePixelScript() {
      if (!this.hasAddedSnapPixelScript) {
        this.addSnapPixelScript();
        this.hasAddedSnapPixelScript = true;
      }
    },
    setPrimaryVar() {
      cssVars({
        variables: {
          '--primary': this.primary,
          '--defaultInteractive': this.defaultInteractive,
        },
      });
    },
    checkToShowWelcomeBanner() {
      // possible values: joined-group || created-group
      const isFirstSession = localStorage.getItem('first-session-type');
      const createdGroup = isFirstSession === 'created-group';
      let hideBanner = false;
      if (this.user && this.user.id) {
        hideBanner = this.user.hideWelcomeBanner;
      }
      if (hideBanner || this.showBanner === false) {
        this.showBanner = false;
        this.showGroupReminder = false;
        this.showProfileReminder = false;
        this.showInviteButtonReminder = false;
        return;
      }
      if (isFirstSession) {
        if (createdGroup) {
          this.setFirstVisitCreatedGroup();
        } else {
          this.setFirstVisitJoinedGroup();
        }
      } else if (this.isAdmin) {
        this.setBannerForAdmin();
      } else {
        this.setBannerForMember();
      }
    },
    preventBannerFromShowing() {
      this.showBanner = false;
      this.showGroupReminder = false;
      this.showProfileReminder = false;
      this.showInviteButtonReminder = false;

      if (this.user && this.user.id) {
        this.updateCurrentUser({
          user: {
            ...this.user,
            hideWelcomeBanner: true,
          },
        });
      }
    },
    setBannerForAdmin() {
      const hasOpenedInviteModal = localStorage.getItem('invite-modal-opened');
      if (this.groupEdited && this.profileEdited) {
        this.preventBannerFromShowing();
        return;
      }
      this.welcomeBannerType = {
        type: 'created-group',
        option1: {
          label: 'Complete your Profile',
          value: this.profileEdited,
        },
        option2: {
          label: 'Update your Group',
          value: this.groupEdited,
        },
        option3: {
          label: 'Invite your Friends',
          value: hasOpenedInviteModal,
        },
      };
      this.showGroupReminder = !this.groupEdited;
      this.showProfileReminder = !this.profileEdited;
      this.showProfileReminder = !hasOpenedInviteModal;
      this.showBanner = true;
      this.nextStepsComplete = !!hasOpenedInviteModal && !!this.profileEdited && !!this.groupEdited;
    },
    setBannerForMember() {
      const hasOpenedInviteModal = localStorage.getItem('invite-modal-opened');
      if (this.profileEdited) {
        this.preventBannerFromShowing();
        return;
      }
      this.welcomeBannerType = {
        type: 'joined-group',
        option1: {
          label: 'Explore the Members',
          value: true,
        },
        option2: {
          label: 'Complete your Profile',
          value: this.profileEdited,
        },
        option3: {
          label: 'Invite your Friends',
          value: hasOpenedInviteModal,
        },
      };
      this.showProfileReminder = !this.profileEdited;
      this.showInviteButtonReminder = !hasOpenedInviteModal;
      this.showBanner = true;
      this.nextStepsComplete = !!hasOpenedInviteModal && !!this.profileEdited;
    },
    setFirstVisitCreatedGroup() {
      const isFirstSession = localStorage.getItem('first-session-type');
      const hasOpenedInviteModal = localStorage.getItem('invite-modal-opened');

      this.welcomeBannerType = {
        type: isFirstSession,
        option1: {
          label: 'Complete your Profile',
          value: this.profileEdited,
        },
        option2: {
          label: 'Update your Group',
          value: this.groupEdited,
        },
        option3: {
          label: 'Invite your Friends',
          value: hasOpenedInviteModal,
        },
      };
      this.showGroupReminder = !this.groupEdited;
      this.showProfileReminder = !this.profileEdited;
      this.showInviteButtonReminder = !hasOpenedInviteModal;
      this.showBanner = true;
      this.nextStepsComplete = !!hasOpenedInviteModal && !!this.profileEdited && !!this.groupEdited;
    },
    setFirstVisitJoinedGroup() {
      const hasOpenedInviteModal = localStorage.getItem('invite-modal-opened');
      this.welcomeBannerType = {
        type: 'joined-group',
        option1: {
          label: 'Explore the Members',
          value: true,
        },
        option2: {
          label: 'Complete your Profile',
          value: this.profileEdited,
        },
        option3: {
          label: 'Invite your Friends',
          value: hasOpenedInviteModal,
        },
      };
      this.showProfileReminder = !this.profileEdited;
      this.showInviteButtonReminder = !hasOpenedInviteModal;
      this.showBanner = true;
      this.nextStepsComplete = !!hasOpenedInviteModal && !!this.profileEdited;
    },
    updatePrimaryColor() {
      if (
        this.$route.params.group &&
        this.group &&
        this.group.primaryColor &&
        this.group.primaryColor !== this.primary
      ) {
        this.primary = this.group.primaryColor;
        this.defaultInteractive = this.group.primaryColor;
        this.setPrimaryVar();
      } else if (!this.$route.params.group && this.reunacyColor !== this.primary) {
        this.primary = this.reunacyColor;
        this.defaultInteractive = this.reunacyColor;
        this.setPrimaryVar();
      }
    },
    checkForIncompleteProfile() {
      const accessToken = localStorage.getItem('access-token');
      if (!!accessToken && Object.keys(this.user).length === 0) {
        this.fetchCurrentUser();
      }
    },
    checkAuthentication() {
      const token = localStorage.getItem('access-token');
      if (token && this.$route.params.group && this.memberOfGroup) {
        this.$store.commit('SET_IS_AUTHENTICATED', true);
        return true;
      }
      return false;
    },
    addSnapPixelScript() {
      const snapPixelScript = document.createElement('script');
      snapPixelScript.setAttribute('type', 'text/javascript');
      snapPixelScript.innerHTML = `
        (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
          {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
            a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
            r.src=n;var u=t.getElementsByTagName(s)[0];
            u.parentNode.insertBefore(r,u);})(window,document,
                  'https://sc-static.net/scevent.min.js');
          snaptr('init', '6832f383-b240-4f38-b57a-24ab4f7f2cdb', {
            'user_email': '${this.user && this.user.email}'
          });
          snaptr('track', 'PAGE_VIEW');`;
      document.head.appendChild(snapPixelScript);
    },
  },
};
</script>

<style>
#app {
  @media print {
    padding: 0;
  }
}

h1 {
  @apply text-lg my-2;
}
</style>
