export function pick(obj, ...props) {
  return props.reduce((result, prop) => {
    result[prop] = obj[prop];
    return result;
  }, {});
}

export function objectsHaveChanged(obj1, obj2) {
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return true;
  }

  for (const key in obj1) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj2.hasOwnProperty(key)) {
      if (obj1[key] !== obj2[key]) {
        return true;
      }
    } else {
      return true;
    }
  }

  return false;
}
