import Vue from 'vue';
import VueRouter from 'vue-router';
import authenticated from './guards/authenticated';

Vue.use(VueRouter);

const routes = [
  {
    component: () => import(/* webpackChunkName: "home" */ './views/Home.vue'),
    name: 'home',
    path: '/',
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem('access-token');
      if (token) return next({ name: 'groups' });
      return next({ name: 'login' });
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ './views/User/Login.vue'),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem('access-token');
      if (token) return next({ name: 'groups' });
      return next();
    },
  },
  {
    beforeEnter: (to, from, next) => authenticated(to, from, next),
    path: '/account',
    name: 'account',
    component: () => import(/* webpackChunkName: "account" */ './views/User/Account.vue'),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "password" */ './views/User/ForgotPassword.vue'),
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "password" */ './views/User/ResetPassword.vue'),
  },
  {
    path: '/create-member',
    name: 'create-member',
    component: () => import(/* webpackChunkName: "password" */ './views/User/CreateMember.vue'),
  },
  {
    path: '/settings',
    name: 'settings',
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem('access-token');
      if (token) return next();
      return next({
        name: 'login',
        query: {
          redirectUrl: to.fullPath,
        },
      });
    },
    component: () => import(/* webpackChunkName: "settings" */ './views/User/Settings.vue'),
  },
  {
    path: '/group',
    name: 'groups',
    component: () => import(/* webpackChunkName: "group" */ './views/User/GroupSelect.vue'),
  },
  {
    path: '/public/:group',
    name: 'public-group',
    component: () => import(/* webpackChunkName: "public" */ './views/Public/Index.vue'),
    children: [
      {
        component: () => import(/* webpackChunkName: "public" */ './views/Public/Members.vue'),
        name: 'public-members',
        path: 'members',
      },
      {
        component: () => {
          return import(/* webpackChunkName: "public" */ './views/Public/GroupDescription.vue');
        },
        name: 'public-description',
        path: 'description',
      },
      {
        component: () => import(/* webpackChunkName: "public" */ './views/Public/Profile.vue'),
        name: 'public-profile',
        path: 'profile/:user',
        meta: { disableScroll: true },
      },
    ],
  },
  {
    path: '/group/:group',
    component: () => import(/* webpackChunkName: "group" */ './views/Group/Index.vue'),
    children: [
      {
        component: () => import(/* webpackChunkName: "group" */ './views/Group/Members.vue'),
        name: 'members',
        path: 'members',
      },
      {
        component: () => {
          return import(/* webpackChunkName: "group" */ './views/Group/GroupDescription.vue');
        },
        name: 'description',
        path: 'description',
      },
      {
        component: () => import(/* webpackChunkName: "group" */ './views/Group/Admin.vue'),
        name: 'admin',
        path: 'admin',
        props: true,
      },
      {
        component: () => import(/* webpackChunkName: "group" */ './views/Group/Profile.vue'),
        name: 'profile',
        path: 'profile/:user',
        meta: { disableScroll: true },
      },
      {
        component: () => import(/* webpackChunkName: "group" */ './views/User/Signup.vue'),
        name: 'signup',
        path: 'signup',
      },
      {
        component: () => import(/* webpackChunkName: "group" */ './views/Group/GroupPreview.vue'),
        name: 'preview',
        path: 'preview',
      },
      {
        component: () => import(/* webpackChunkName: "group" */ './views/Group/JoinGroup.vue'),
        name: 'join-group',
        path: 'join',
      },
      {
        component: () => import(/* webpackChunkName: "group" */ './views/Group/Home.vue'),
        name: 'group',
        path: '',
        redirect: {
          name: 'members',
        },
      },
      {
        component: () => {
          return import(/* webpackChunkName: "group" */ './views/Group/CreateEventFlow.vue');
        },
        name: 'create-event',
        path: 'event-create',
      },
      {
        component: () => import(/* webpackChunkName: "group" */ './views/Group/GroupEventList.vue'),
        name: 'events',
        path: 'events',
      },
      {
        component: () => import(/* webpackChunkName: "group" */ './views/Group/Photos.vue'),
        name: 'photos',
        path: 'photos',
      },
      {
        component: () => import(/* webpackChunkName: "group" */ './views/Group/Feed.vue'),
        name: 'feed',
        path: 'feed',
      },
      {
        component: () => import(/* webpackChunkName: "group" */ './views/Group/GroupEvent.vue'),
        name: 'event',
        path: 'events/:eventId',
      },
    ],
  },
  {
    path: '/create-group',
    name: 'create-group',
    component: () => import(/* webpackChunkName: "group" */ './views/Group/CreateGroupFlow.vue'),
  },
  {
    component: () => import(/* webpackChunkName: "user" */ './views/Group/Profile.vue'),
    name: 'user-profile',
    path: '/profile',
  },
  {
    component: () => import(/* webpackChunkName: "superadmin" */ './views/SuperAdmin/Index.vue'),
    name: 'super-admin',
    path: '/super-admin',
  },
  {
    path: '/sandbox',
    name: 'sandbox',
    component: () => import(/* webpackChunkName: "sandbox" */ './views/Sandbox.vue'),
  },
  {
    component: () => import(/* webpackChunkName: "group" */ './views/NotFound.vue'),
    name: 'not-found',
    path: '/404',
  },
  {
    path: '*',
    redirect: { name: 'not-found' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.matched.some((m) => m.meta.disableScroll)) {
      return {};
    }

    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return {
        selector: to.hash,
      };
    }

    return {
      x: 0,
      y: 0,
    };
  },
  routes,
});

export default router;
