import axios from 'axios';
import humps from 'humps';
import { constants } from '../config';

const { URL } = constants;

/*
 * Humps breaks when your data object has a File object in it, so this code is used to remedy
 * that.
 *
 * Taken from : https://github.com/domchristie/humps/issues/51#issuecomment-425113505
 */
const decamelizeThatDoesNotBreakFile = (object) => {
  if (object && !(object instanceof File)) {
    if (object instanceof Array) {
      return object.map((item) => decamelizeThatDoesNotBreakFile(item));
    }
    if (object instanceof FormData) {
      const formData = new FormData();
      for (const [key, value] of object.entries()) {
        formData.append(humps.decamelize(key), value);
      }
      return formData;
    }
    if (typeof object === 'object') {
      return Object.keys(object).reduce((acc, next) => ({
        ...acc,
        [humps.decamelize(next)]: decamelizeThatDoesNotBreakFile(object[next]),
      }), {});
    }
  }
  return object;
};

const api = axios.create({
  baseURL: URL,
  transformResponse: [
    ...axios.defaults.transformResponse,
    (data) => humps.camelizeKeys(data),
  ],
  transformRequest: [
    (data) => decamelizeThatDoesNotBreakFile(data),
    ...axios.defaults.transformRequest,
  ],
});

export default api;
